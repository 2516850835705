import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TopImage from "../components/top-image"
import SEO from '../components/seo'
import DynamicColor from '../components/dynamic-color'
import "../styles/menu.scss"

export default function Menu({ data }) {

  const products = data.allProduct.nodes
  let categories = data.allCategory.nodes
  categories = categories.sort((a, b) => a.index - b.index)
  categories.forEach(c => {
    c.products = products.filter(p => p.categoryId === c.id).sort(((a, b) => (a.index < b.index ? -1 : 1)))
    console.log(c.products)
  })


  return (
    <Layout data={data}>
      <SEO />
      <DynamicColor data={data} />
      <div className="menu-page">
        <TopImage images={data.restaurant.websiteSettings?.dishImages || data.restaurant.websiteSettings?.restoImages || []} />
        {/* <h1>Notre carte</h1> */}
        <div className="menu">
          {categories.map(c => {
            return c.displayApp == false ? <></> :
              <div className="section" key={c.id}>
                <div className="section-title">
                  <h3 className="text-2xl font-bold">
                    {c.name.toUpperCase()}
                  </h3>
                </div>

                <div className="products">
                  {
                    c.products.map(p => {
                      return p.displayApp == false ? <></> :
                        <div className="product-row sm:basis-full py-2 px-2 md:basis-5/12 md:px-52 md:py-2" key={p.id}>
                          {p.image?.path && (
                            <img src={`${p.image.path}`} alt={p.name} />
                          )}
                          {!p.image?.path && (
                            <div style={{ maxWidth: "90px" }} />
                          )}
                          <div className="product-name">
                            <div className="text-lg">{p.name}</div>
                            <div className="text-gray-500">{p.description}</div>
                          </div>
                          {p.unitPrice && (
                            <div className="text-lg product-price">{p.unitPrice.toFixed(2)}</div>
                          )}
                        </div>
                    })
                  }
                </div>
              </div>
          })}
        </div>
      </div>
    </Layout >
  )
}


export const query = graphql`
  query PageQuery3 ($restoId: String!) {
    restaurant(id: {eq: $restoId}) {
      urlName
      informations {
        businessType
        address
        postalCode
        city
        name
        phone
        restoId
      }
      settings {
        color
        takeaway
        delivery
      }
      websiteSettings {
        reservation
        googleMapsUrl
        deliverooUrl
        uberEatsUrl
        logo {
          path
        }
        restoImages {
          path
        }
        linkFacebook
        linkInstagram
        linkYelp
        linkTwitter
        linkFoursquare
        linkTripadvisor
        color
        colorSecond
        colorBackground
      }
    }
    allProduct {
      nodes {
        id
        index
        name
        description
        displayApp
        image {
          path
        }
        unitPrice
        categoryId
      }
    }
    allCategory {
      nodes {
        id
        name
        displayApp
        index
      }
    }
  }
`