import React from "react"
import '../styles/top-image.scss'

export default function TopImage({ images }) {

  return (
    <div className="header">
      {images.map((img, idx) => {
        if (idx <= 0)
          return (
            <div key={`content-${idx}`} className="my-slide content">
              <img src={`${img.path}`} alt="img" />
            </div>
          )
      })}
    </div>
  )
}
